import React from 'react';

export const LanguageContext = React.createContext('english');

class LanguageProvider extends React.Component {
  render() {
    const { value } = this.props;
    return (
      <LanguageContext.Provider value={value}>
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}
export default LanguageProvider;
