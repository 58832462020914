import React, { Component } from 'react';
import { Flex, Button, Box, Text, Image } from 'rebass';
import styled from 'styled-components';
import { height, overflow } from 'styled-system';
import { LanguageContext } from '../Components/Language/Language';
import logo from '../imgs/BMO.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const HFlex = styled(Flex)`
  ${height}
`;

const Bar = styled(HFlex)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 3rem;
  z-index: 4;
`;

class NavBar extends Component {
  constructor(props) {
    super(props);

    const support = this.isSupported();
    this.state = {
      isSupported: support,
    };
  }
  onSubmitLang = () => {
    this.props.OnChangeLanguage();
  };

  isSupported = () => {
    const isFirefox = window.navigator.userAgent.match(/(Firefox)/);
    const isSamsung = window.navigator.userAgent.match(/(SamsungBrowser)/);
    var msie = window.navigator.userAgent.indexOf('MSIE ');
    var trident = window.navigator.userAgent.indexOf('Trident/');
    var edge = window.navigator.userAgent.indexOf('Edge/');

    let isIE = msie > 0 || trident > 0 || edge > 0;
    return (
      window.navigator.userAgent.match(/(Chrome||Safari)/) &&
      !isFirefox &&
      !isSamsung &&
      !isIE
    );
  };

  onDynamic = () => {
    const otherRootId = '#animated-site';
    const fallback = document.querySelector(otherRootId);
    if (fallback === null) {
      window.location.href = 'http://womens.appspot.com';
    } else {
      fallback.classList.toggle('hidden');
      document.querySelector('#fallback-site').classList.toggle('hidden');
    }
  };

  render() {
    const { isSupported } = this.state;
    const dynamic = isSupported ? (
      <Button variant="roundNav" id="animation-btn" onClick={this.onDynamic}>
        <FontAwesomeIcon icon={['fa', 'eye-slash']} />
      </Button>
    ) : null;
    return (
      <LanguageContext.Consumer>
        {value => (
          <Bar bg={this.props.bg || 'BMOBlue'}>
            <Image height="3rem" src={logo} m="20" />
            <Box mx="auto" />
            <Box mr="20px">
              {dynamic}
              <Button variant="roundNav" onClick={this.onSubmitLang}>
                <Text fontFamily="secondary">{value.abv}</Text>
              </Button>
            </Box>
          </Bar>
        )}
      </LanguageContext.Consumer>
    );
  }
}
export default NavBar;

export const Footer = props => (
  <Bar
    flex="2"
    justifyContent="center"
    alignItems="center"
    alignContent="center"
    {...props}
  >
    <Text color="white" fontSize={[1, 2, 4]} px="2" textAlign="center">
      {props.text}
    </Text>
  </Bar>
);

export const Row = props => <HFlex mx={-3} {...props} />;

export const Column = props => <HFlex flex="1 1 auto" {...props} />;

export const FlexOverflow = styled(HFlex)`
  ${overflow};
`;
