import React from 'react';
import styled from 'styled-components';
import { width, themeGet, space } from 'styled-system';
import { Flex } from 'rebass';

const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledRadioButton = styled(Flex)`
  border-radius: 1.25rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 2.5rem;
  max-width: 24rem;
  text-transform: capitalize;
  ${space};
  ${width};
  background: ${props =>
    props.checked
      ? themeGet('colors.BMOBlueDark', '#333')
      : themeGet('colors.BMOBlue80', '#333')};
  & > span {
    font-weight: bolder;
    color: white;
  }
  &:hover {
    box-shadow: 0 0 0 3px ${themeGet('colors.BMOBlueDark', '#333')};
  }
  &:hover span {
    color: white;
  }
`;
StyledRadioButton.defaultProps = {
  m: '1rem auto',
  p: '0.5rem',
};

const RadioContainer = styled.div`
  min-width: '100%';
`;

export const RadioButton = ({ className, checked, ...props }) => (
  <RadioContainer
    className={className}
    tabIndex={props.tabIndex}
    aria-checked={checked}
  >
    <HiddenRadioButton
      id={props.name + props.label}
      checked={checked}
      {...props}
    />
    <label htmlFor={props.name + props.label}>
      <StyledRadioButton
        checked={checked}
        aria-checked={checked}
        {...props.width}
        {...props.space}
      >
        <span>{props.label}</span>
      </StyledRadioButton>
    </label>
  </RadioContainer>
);

export const FormContainer = styled(Flex)`
  flex-direction: column;
  flex: 4;
  ${space};
`;
FormContainer.defaultProps = {
  px: 3,
};
