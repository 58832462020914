import React from 'react';
import styled from 'styled-components';
import { width, space, color, themeGet } from 'styled-system';
import { Text } from 'rebass';

const ProgressFiller = styled.div`
  position: absolute;
  background: ${themeGet('colors.BMOBlueDark', '#333')};
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
  width: ${props => `${props.precentage}%` || '100%'};
  z-index: 2;
  text-align: center;
  white-space: nowrap;
`;
const ProgressBarContainer = styled.div`
  background: white;
  min-height: 3rem;
  height: 3rem;
  ${width};
  ${space};
  position: relative;
  border-radius: 1.5rem;
`;

const MaxPercentage = styled(Text)`
  position: absolute;
  right: 0;
  top: 25%;
  margin: 0 1.5rem;
  z-index: 1;
`;

const CurrentPrecentage = styled(Text)`
  z-index: 3;
  vertical-align: middle;
  line-height: 3rem;
  padding-left: 1em;
  ${color};
`;

CurrentPrecentage.defaultProps = {
  color: 'white',
};

export const ProgressBar = ({ className, ...props }) => (
  <ProgressBarContainer width={props.width} {...props} height={props.height}>
    <MaxPercentage>100%</MaxPercentage>
    <ProgressFiller precentage={props.precentage}>
      <CurrentPrecentage
        color={props.precentage > 0 ? 'white' : 'black'}
      >{`${props.precentage || 0}%`}</CurrentPrecentage>
    </ProgressFiller>
  </ProgressBarContainer>
);

ProgressBar.defaultProps = {
  m: '2',
  height: '25px',
  width: '500px',
};
