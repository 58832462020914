import React, { Component } from 'react';
import { Button, Flex, Text } from 'rebass';
import { Form } from '@rebass/form';
import { HFlex } from '../Components/NavBar';
import { RadioButton, FormContainer } from '../Components/StyledFormComponents';
import Translate from '../Components/Language/TranslateComponent';
import { ImageFlexBox } from '../Components/ImageFlexBox';
import background from '../imgs/question-bg.jpg';

class QuestionPage extends Component {
  constructor(props) {
    super(props);

    const { PossibleAnswers, AnswerUnit } = props.question;

    // const shuffle = this.shuffleArray(PossibleAnswers);
    this.state = {
      selectedOption: '',
      choices: PossibleAnswers,
      unit: AnswerUnit,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { question: nextQuestion } = nextProps;
    const { question: currentQuestion } = this.props;

    if (nextQuestion.QuestionText !== currentQuestion.QuestionText) {
      // const shuffle = this.shuffleArray(nextQuestion.PossibleAnswers);
      this.setState({
        choices: nextQuestion.PossibleAnswers,
        selectedOption: '',
        unit: nextQuestion.AnswerUnit,
      });
    }
  }

  render() {
    const { question } = this.props;
    const display = question ? this.renderQuestion(question) : null;
    return display;
  }

  renderQuestion(question) {
    return (
      <ImageFlexBox
        backgroundSize="cover"
        backgroundPosition="center"
        flex="1"
        flexWrap="wrap"
        flex-direction={['row', 'row', 'row', 'column']}
        backgroundImage={`url(${background})`}
      >
        <HFlex
          bg="BMOBlue"
          px={3}
          pt={3}
          pb={1}
          flex="1"
          flexDirection="column"
          justifyContent="center"
          width={[1, 1, 1, 1 / 3]}
        >
          <Text
            color="white"
            textAlign={['center']}
            fontSize={[2, 3, 4, 5, 6]}
            fontWeight="400"
          >
            {question.QuestionText}
          </Text>
        </HFlex>
        <Flex
          width={[1, 1, 1, 2 / 3]}
          justifyContent="center"
          alignItems="center"
        >
          <Form
            values={this.state}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            render={({ values, onChange, onSubmit }) => (
              <FormContainer width={[1]}>
                <form onSubmit={onSubmit}>
                  <div role="group">
                    {values.choices.map((x, i) => (
                      <RadioButton
                        name="selectedOption"
                        label={`${values.choices[i]} ${values.unit}`}
                        value={values.choices[i]}
                        key={'answer' + i}
                        checked={values.selectedOption === values.choices[i]}
                        onChange={onChange}
                        autoFocus
                      />
                    ))}
                  </div>
                  <Flex
                    flex="0 0 100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      alignSelf="flex-end"
                      variant="submitQuestion"
                      width={[1, 1 / 2]}
                      disabled={!values.selectedOption}
                      type="submit"
                    >
                      <Translate textId="ScreenText.QuestionScreen.SubmitAnswer" />
                    </Button>
                  </Flex>
                </form>
              </FormContainer>
            )}
          />
        </Flex>
      </ImageFlexBox>
    );
  }

  onSubmit = () => {
    const { PossibleAnswers, CorrectAnswer } = this.props.question;
    const { id } = this.props;

    const status = {
      id: PossibleAnswers.indexOf(this.state.selectedOption),
      answer: this.state.selectedOption,
      correct: CorrectAnswer === this.state.selectedOption,
    };
    this.props.OnConfirm(id, status);
    this.props.history.push(`/answer/`, { qID: id });
  };

  onError = () => {
    this.props.history.push(`/error`);
  };

  onChange = values => {
    const { selectedOption } = values;
    this.setState({
      selectedOption: selectedOption,
    });
  };

  shuffleArray = array => {
    const a = array.slice();

    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }

    return a;
  };
}

export default QuestionPage;
