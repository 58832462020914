import { library } from '@fortawesome/fontawesome-svg-core';

// import icons
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

library.add(faFacebookF, faTwitter, faLinkedinIn, faEye, faEyeSlash);
