import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { gameText } from '../../text/gameText';
import { LanguageContext } from './Language';

class Translate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      text: {
        english: gameText.english,
        french: gameText.french,
      },
    };
  }
  render() {
    const { text } = this.state;
    const { textId = '', fallback, tokenSplit, data } = this.props;

    return (
      <LanguageContext.Consumer>
        {value =>
          data
            ? parseTpl(
                resolvePath(text[value.lang], textId, fallback, tokenSplit),
                data
              )
            : resolvePath(text[value.lang], textId, fallback, tokenSplit)
        }
      </LanguageContext.Consumer>
    );
  }
}
export default Translate;

Translate.propTypes = {
  textId: PropTypes.string.isRequired,
  fallback: PropTypes.node,
  tokenSplit: PropTypes.string,
};

Translate.defaultProps = {
  tokenSplit: '.',
};

export const resolvePath = (object, path, defaultValue, tokenSplit) =>
  path
    .split(tokenSplit)
    .reduce((o, p) => (Reflect.has(o, p) ? o[p] : defaultValue), object);

const get = (path, obj, fb = `$\{${path}}`) => {
  return path.split('.').reduce((o, p) => (Reflect.has(o, p) ? o[p] : fb), obj);
};

const parseTpl = (template, map, fallback) => {
  return template.replace(/\$\{.+?}/g, match => {
    const path = match.substr(2, match.length - 3).trim();
    return get(path, map, fallback);
  });
};
