import React, { Component } from 'react';
import { Button, Link, Flex, Text } from 'rebass';
import { ImageFlexBox } from '../Components/ImageFlexBox';
import { ProgressBar } from '../Components/StyledProgressBar';
import { Column, FlexOverflow } from '../Components/NavBar';
import styled from 'styled-components';
import Translate from '../Components/Language/TranslateComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gameText } from '../text/gameText';
import { Hide } from '../Components/hideComponent';
import { themeGet } from 'styled-system';
const SocialButton = styled(Link)`
  width: 4rem;
  height: 4rem;
  color: ${themeGet('colors.BMOBlue', '#333')}
  font-size: 1.5rem;
  background: white;
  border-radius: 50%;
  margin: 0.25rem;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  line-height: 4rem;
  &:hover {
    color: white;
    background:  ${themeGet('colors.BMOBlueDark', '#333')}
  }
`;

const ContactButton = styled(Link)`
  color: ${themeGet('colors.BMOBlue', '#333')};
  border-radius: 1.5rem /50%;
  padding: 1rem;
  min-height: 3rem;
  max-width: 24rem;
  background: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  text-decoration: none;
  &:hover {
    background: ${themeGet('colors.BMOBlue', '#333')};
    color: white;
  }
`;

const MobileLabel = styled(Button)`
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0px;
  height: 100%;
  vertical-align: center;
  & > span {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    background: ${themeGet('colors.BMOGrey', '#333')};
    color: white;
  }
  &:focus {
    outline: none;
  }
`;
MobileLabel.defaultProps = {
  background: themeGet('colors.BMOBlueDark', '#333'),
};

class StatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {
        english: gameText.english,
        french: gameText.french,
      },
      atTop: true,
    };

    this.contactRef = React.createRef();
    this.topRef = React.createRef();
  }
  onSubmit = () => {
    this.props.OnConfirm();
    this.props.history.push('/');
  };

  scrollTo = () => {
    const { atTop } = this.state;

    if (atTop) {
      if (!this.contactRef.current) return;

      this.contactRef.current.scrollIntoView();
      this.setState({
        atTop: false,
      });
    } else {
      this.topRef.current.scrollIntoView();
      this.setState({
        atTop: true,
      });
    }
  };

  render() {
    const { atTop } = this.state;
    const { playerStats, lang } = this.props;
    const total = playerStats.length;
    const correctCount = playerStats.filter(x => x.correct).length;

    const percent = (correctCount / total) * 100;
    const fb = this.state.text[lang].ScreenText.Social.FB;
    const linkedin = this.state.text[lang].ScreenText.Social.Linkedin;
    const twitter = this.state.text[lang].ScreenText.Social.Twitter;
    const contact = this.state.text[lang].ScreenText.Social.Contact;
    const link = this.state.text[lang].ScreenText.StatsScreen.siteLink;
    return (
      <ImageFlexBox
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={['top right', 'top center']}
        flex="1"
        flexWrap="wrap"
        flex-direction={['row', 'row', 'row', 'column']}
        bg={'BMOBlue'}
        ref={this.topRef}
      >
        <Column
          width={[1, 1, 1, 2 / 3]}
          alignItems="flex-start"
          height={'100%'}
          flexDirection="column"
          justifyContent="center"
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex="14"
            px={[1, 1, 3]}
            width={1}
            pt="4em"
          >
            <Text
              color="white"
              fontSize={[4, 5, 5, 6]}
              my={[1, 1, 2, 2]}
              textAlign="center"
            >
              <Translate
                textId="ScreenText.StatsScreen.Score"
                fallback="You Scored:"
              />
              {`${correctCount || 0} / ${total || 4} `}
            </Text>
            <ProgressBar
              precentage={percent}
              width={[4 / 5, 2 / 3]}
              unit="accurate"
              height={'3rem'}
            />
            <Text
              color="white"
              fontSize={[2, 3, 4]}
              my={[1, 1, 2, 2]}
              p={[2, 2, 2, 2]}
              width={[1, 1, 1, 4 / 5]}
              fontWeight="lighter"
              textAlign={['center', 'center']}
            >
              <Translate
                textId="ScreenText.StatsScreen.additionalP1"
                fallback="Follow up text"
              />
            </Text>
            <Flex mb={[2, 2, 2, 2]}>
              <SocialButton
                variant="share"
                href={fb}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </SocialButton>
              <SocialButton
                variant="share"
                href={twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </SocialButton>
              <SocialButton
                variant="share"
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
              </SocialButton>
            </Flex>
            <Text
              color="white"
              fontSize={[2, 3, 4]}
              mx="1"
              my={[1, 1, 2, 2]}
              p={[2, 2, 2, 2]}
              fontWeight="100"
              width={[1, 3 / 4, 1, 4 / 5]}
              textAlign={['center']}
            >
              <Translate
                textId="ScreenText.StatsScreen.additionalP2"
                fallback="Some more text"
              />
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'white' }}
              >
                <Translate
                  textId="ScreenText.StatsScreen.siteLinkLabel"
                  fallback="Some more text"
                />
              </a>
            </Text>
          </Flex>
          <Hide breakpoints={[3]} width="100%" height="5rem" minHeight="5rem">
            <MobileLabel
              bg={atTop ? 'BMOBlueDark' : 'BMOBlue'}
              width="100%"
              onClick={this.scrollTo}
              ref={this.contactRef}
            >
              <Text color="white" as="h2" fontWeight="300">
                <Translate
                  textId={
                    atTop
                      ? 'ScreenText.StatsScreen.betterBankingLabel'
                      : 'ScreenText.StatsScreen.back'
                  }
                  fallback={atTop ? 'Learn More' : 'Back'}
                />
              </Text>
            </MobileLabel>
          </Hide>
        </Column>
        <Column
          width={[1, 1, 1, 1 / 3]}
          height="100%"
          bg={'BMOBlueDark'}
          flexDirection="column"
          justifyContent={['center', 'center']}
          alignSelf="flex-end"
        >
          <FlexOverflow
            flexDirection="column"
            px={[16, null, null, 64]}
            py={16}
            overflow="auto"
          >
            <Text color="white" fontSize={[2, 3, 3, 4]} my="2">
              <Translate
                textId="ScreenText.StatsScreen.contactP1"
                fallback="Contact"
              />
            </Text>
            <ContactButton
              tabIndex="0"
              my={[1, 1, 2, 2]}
              width={['90%']}
              aria-label={'Contact'}
              href={contact}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Translate
                textId="ScreenText.StatsScreen.connectButton"
                fallback="Contact"
              />
            </ContactButton>
          </FlexOverflow>
        </Column>
      </ImageFlexBox>
    );
  }
}

export default StatusPage;
