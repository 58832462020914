import styled from 'styled-components';
import {
  width,
  height,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  overflow,
} from 'styled-system';
import { Flex } from 'rebass';

const themed = key => props => props.theme[key];

export const ImageFlexBox = styled(Flex)(
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  width,
  height,
  overflow,
  themed('ImageBox')
);
ImageFlexBox.propTypes = {
  ...backgroundImage.propTypes,
  ...backgroundSize.propTypes,
  ...backgroundPosition.propTypes,
  ...backgroundPosition.propTypes,
  ...width.propTypes,
  ...height.propTypes,
};
