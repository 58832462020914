import React, { Component } from 'react';
import { Button, Heading, Flex, Text } from 'rebass';
import { ImageFlexBox } from '../Components/ImageFlexBox';
import Translate from '../Components/Language/TranslateComponent';
import { Column, FlexOverflow } from '../Components/NavBar';
import background from '../imgs/bg-photo.jpg';
import { Hide } from '../Components/hideComponent';
import { themeGet } from 'styled-system';
import styled from 'styled-components';

const MobileLabel = styled(Button)`
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  text-decoration: none;
  border-radius: 0px;
  height: 100%;
  vertical-align: center;
  & > span {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    background: ${themeGet('colors.BMOGrey80', '#333')};
    color: white;
  }
  &:focus {
    outline: none;
  }
`;
MobileLabel.defaultProps = {
  background: themeGet('colors.BMOBlueDark', '#333'),
};

class IntroPage extends Component {
  constructor(props) {
    super(props);
    this.state = { atTop: true };
    this.learnMoreRef = React.createRef();
    this.top = React.createRef();
  }
  scrollTo = () => {
    const { atTop } = this.state;

    if (atTop) {
      if (!this.learnMoreRef.current) return;
      this.learnMoreRef.current.scrollIntoView();
      this.setState({
        atTop: false,
      });
    } else {
      this.top.current.scrollIntoView();
      this.setState({
        atTop: true,
      });
    }
  };

  render() {
    const { atTop } = this.state;
    return (
      <ImageFlexBox
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={['top right', 'top center']}
        flex="1"
        flexWrap="wrap"
        flex-direction={['row', 'row', 'row', 'column']}
        backgroundImage={`url(${background})`}
        bg={'BMOBlueDark'}
        ref={this.top}
      >
        <Column
          width={[1, 1, 1, 2 / 3]}
          alignItems="flex-start"
          height={'100%'}
          flexDirection="column"
          justifyContent="center"
          pt="2em"
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            flex="16"
            px={3}
            width={1}
          >
            <Heading
              color="white"
              fontSize={[6, 7]}
              px="2"
              mb={[1, 2, 2, 0]}
              as="h1"
            >
              <Translate
                textId="ScreenText.IntroScreen.title"
                fallback="title"
              />
            </Heading>
            <Text
              color="white"
              fontSize={[2, 3, 4]}
              mb={4}
              px="2"
              width={[1, 1, 2 / 3]}
              maxWidth={1}
              as="p"
            >
              <Translate
                textId="ScreenText.IntroScreen.tagline"
                fallback="Call to action"
              />
            </Text>
            <Button
              variant="submit"
              mb="2rem"
              onClick={this.onSubmit}
              autoFocus
              fontSize={[1]}
              textAlign="left"
              width={[1, 1, 1 / 4]}
            >
              <Translate
                textId="ScreenText.IntroScreen.startButton"
                fallback="Start"
              />
            </Button>
          </Flex>
          <Hide breakpoints={[3]} height="5rem" minHeight="5rem" width="100%">
            <MobileLabel
              bg="BMOBlue"
              width="100%"
              onClick={this.scrollTo}
              ref={this.learnMoreRef}
            >
              <Text color="white" as="h2" fontWeight="300">
                <Translate
                  textId={
                    atTop
                      ? 'ScreenText.IntroScreen.learnMore'
                      : 'ScreenText.IntroScreen.back'
                  }
                  fallback={atTop ? 'Learn More' : 'Back'}
                />
              </Text>
            </MobileLabel>
          </Hide>
        </Column>
        <Column
          width={[1, null, null, 1 / 3]}
          height="100%"
          bg={'BMOBlueDark'}
          flexDirection="column"
          justifyContent={['center']}
          alignSelf="flex-end"
        >
          <FlexOverflow
            flexDirection="column"
            px={[8, null, null, 16]}
            py={['2em', '2rem', '3rem', 0]}
            overflow="auto"
          >
            <Text color="white" fontSize={[2, 3, 3, 3]} my="2">
              <Translate
                textId="ScreenText.IntroScreen.additionalP1"
                fallback="Start"
              />
            </Text>
            <Text color="white" fontSize={[2, 3, 3, 3]} my="2">
              <Translate
                textId="ScreenText.IntroScreen.additionalP2"
                fallback="Start"
              />
            </Text>
          </FlexOverflow>
        </Column>
      </ImageFlexBox>
    );
  }

  onSubmit = () => {
    this.props.history.push('/question', { qID: 0 });
  };
}
export default IntroPage;
