import 'react-app-polyfill/ie11'; // ensures browser suppport must be first line
import 'core-js/fn/reflect';
import 'core-js/es7/array';
import 'url-polyfill';
import ReactGA from 'react-ga';
import React, { Component } from 'react';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Route, Switch, Router } from 'react-router-dom';
import './index.css';
import './fontawesome';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import QuestionPage from './Pages/QuestionPage';
import AnswerFollowUp from './Pages/AnswerFollowup';
import StatusPage from './Pages/StatusPage';
import IntroPage from './Pages/IntroPage';
import history from './Components/History';
import LanguageProvider from './Components/Language/Language';
import { gameText } from './text/gameText';
import NavBar from './Components/NavBar';
import { HFlex } from './Components/NavBar';

class App extends Component {
  constructor(props) {
    super(props);
    this.quizStatus = [];
    this.allLangs = ['english', 'french'];

    let selLang = window.location.href.indexOf('fr') > -1 ? 1 : 0;
    const size = gameText[this.allLangs[selLang]]['Questions'].length;
    this.state = {
      question: 0,
      lang: this.allLangs[selLang],
      lang_short: this.allLangs[1 - selLang].substr(0, 2),
      size: size,
      navColor: 'transparent',
    };

    this.NavRef = React.createRef();

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  componentDidMount() {
    console.log('start');
    this.initReactGA();
    window.scrollTo(0, this.NavRef.current.offsetTop);

    this.backListener = history.listen((location, action) => {
      const currentLoc = location.pathname;
      if (currentLoc === '/question' || currentLoc === '/answer') {
        this.setState({
          navColor: 'BMOBlue',
        });
      } else {
        this.setState({
          navColor: 'transparent',
        });
      }
      if (location.state && action === 'POP') {
        const { qID = 0 } = location.state;
        this.setState({
          question: qID,
        });
      }
    });
  }

  componentWillUnmount() {
    // Unbind listener
    this.backListener();
  }
  initReactGA = () => {
    ReactGA.initialize('UA-134360793-1');
    ReactGA.pageview('/');

    history.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search);
    });
  };

  HandleConfirm = (question, status) => {
    this.quizStatus[question] = status;
    this.SubmitAnswersGA(status);
  };

  SubmitAnswersGA = status => {
    const score = parseInt(status.answer, 10);
    ReactGA.event({
      category: 'Question Submission',
      action: 'User Answered Question',
      label: `Question${status.id}`,
      value: score || 0,
      metric1: score,
    });
  };

  AdvanceToNextQuestion = () => {
    this.setState(
      prevState => ({
        question: prevState.question + 1,
      }),
      this.OnAdvance
    );
  };

  AdvanceToFollowUp = () => {
    history.push('/followup', { qID: this.state.question });
  };

  ResetGame = () => {
    this.quizStatus = [];
    this.setState({
      question: 0,
    });
  };

  OnAdvance = () => {
    const { question, size } = this.state;

    if (question >= size) {
      history.push('/stats', { qID: this.state.question });
    } else {
      history.push('/question', { qID: this.state.question });
    }
  };
  OnChangeLanguage = () => {
    this.setState(prevState => ({
      lang:
        prevState.lang === this.allLangs[0]
          ? this.allLangs[1]
          : this.allLangs[0],
      lang_short:
        prevState.lang === this.allLangs[0]
          ? this.allLangs[0].substr(0, 2)
          : this.allLangs[1].substr(0, 2),
    }));
  };

  render() {
    const { question: id = 0, lang, lang_short, navColor } = this.state;
    const question = gameText[lang]['Questions'][id > 0 ? id : 0];
    return (
      <ThemeProvider theme={theme}>
        <LanguageProvider value={{ lang: lang, abv: lang_short }}>
          <Router history={history} basename={process.env.PUBLIC_URL}>
            <HFlex
              className="app scrollable"
              role="main"
              bg="BMOBlue"
              flexDirection="column"
            >
              <NavBar
                bg={navColor}
                OnChangeLanguage={this.OnChangeLanguage}
                ref={this.NavRef}
              />
              <HFlex height="100%" width="100%">
                <Switch>
                  <Route exact path="/" component={IntroPage} />
                  <Route
                    path="/question/"
                    render={props => (
                      <QuestionPage
                        {...props}
                        question={question}
                        OnConfirm={this.HandleConfirm}
                        id={id}
                        lang={lang}
                      />
                    )}
                  />
                  <Route
                    path="/answer/"
                    render={props => (
                      <AnswerFollowUp
                        {...props}
                        question={question}
                        selectedAnswer={this.quizStatus[id]}
                        lang={lang}
                        OnConfirm={this.AdvanceToNextQuestion}
                      />
                    )}
                  />
                  <Route
                    path="/stats/"
                    render={props => (
                      <StatusPage
                        {...props}
                        playerStats={this.quizStatus}
                        OnConfirm={this.ResetGame}
                        lang={lang}
                      />
                    )}
                  />
                  <Route component={IntroPage} lang={lang} />
                </Switch>
              </HFlex>
            </HFlex>
          </Router>
        </LanguageProvider>
      </ThemeProvider>
    );
  }
}
export default App;

render(<App />, document.getElementById('fallback-site'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
