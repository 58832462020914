/*eslint no-template-curly-in-string: "off"*/

export const Questions = [
  {
    QuestionText:
      'D’ici 2020, à quel montant se chiffre le patrimoine privé que les femmes devraient détenir (en billions)?',
    PossibleAnswers: ['10', '24', '72', '150'],
    AnswerUnit: 'Billions',
    CorrectAnswer: '72',
    Image: '',
    FollowText:
      '72 billions $ (32 % du total). Selon le Boston Consulting Group, entre 2010 et 2015, le patrimoine privé détenu par des femmes est passé de 34 billions $ à 51 billions $. D’ici 2020, elles devraient en détenir 72 billions $, soit 32 % du total. Et la plus grande partie du patrimoine privé qui changera de mains dans les décennies à venir ira probablement aux femmes. ',
    FollowSource:
      'Source: https://www.economist.com/graphic-detail/2018/03/08/womens-wealth-is-rising',
  },
  {
    QuestionText:
      'Dans quelle mesure les résultats financiers des entreprises qui ont une plus grande diversité des sexes dans leurs équipes de direction sont-ils meilleurs?',
    PossibleAnswers: ['10,5', '22,6', '36,4', '42,4'],
    AnswerUnit: '%',
    CorrectAnswer: '36,4',
    Image: '',
    FollowText:
      '36,4 %. Les entreprises qui ont des équipes de genres variés connaissent une amélioration de 36,4 % (1) de leurs résultats financiers comparativement à celles qui n’ont pas des équipes aussi diversifiées, et ce n’est pas tout. Les entreprises qui ont une représentation plus élevée de femmes au sein des équipes de direction connaissent également un rendement des actions supérieur de 20 % (2). ',
    FollowSource:
      'Sources: 1. MSCI, Women on Boards: Global Trends in Gender Diversity on Corporate Boards, November 2015 et 2. Credit Suisse, The CS Gender 3000: Women in Senior Management, September 2014.',
  },
  {
    QuestionText:
      'Si les femmes et les hommes avaient une égalité d’accès à l’emploi au sein de la main-d’œuvre à l’échelle mondiale, combien de dollars cela ajouterait-il à l’économie?',
    PossibleAnswers: [
      '10 milliards $',
      '120 milliards $',
      '3 billions $',
      '12 billions $',
    ],
    AnswerUnit: '',
    CorrectAnswer: '12 billions $',
    Image: '',
    FollowText:
      '12 billions $. Une égalité d’accès à l’emploi pour les hommes et les femmes au sein de la main-d’œuvre à l’échelle mondiale ajouterait 12 billions $ à l’économie ce qui équivaut à la taille des PIB actuels de l’Allemagne, du Japon et du Royaume-Uni réunis.',
    FollowSource:
      'Source:  https://www.mckinsey.com/featured-insights/employment-and-growth/how-advancing-womens-equality-can-add-12-trillion-to-global-growth',
  },
  {
    QuestionText:
      'Pendant la période de 2016 à 2017, dans 74 économies du monde entier, combien de femmes ont créé des entreprises à l’échelle mondiale? ',
    PossibleAnswers: ['5', '83', '115', '163'],
    CorrectAnswer: '163',
    AnswerUnit: 'millions',
    Image: '',
    FollowText:
      '163 millions. Ce montant a augmenté de 57 % depuis 2010 où 104 millions de femmes ont créé des entreprises dans 59 économies. Malgré les préjugés et les obstacles auxquels les femmes font face, c’est un exploit remarquable!',
    FollowSource:
      'Source: Global Entrepreneurship Monitor, Women’s Entrepreneurship 2016/2017 Report',
  },
];

export const ScreenText = {
  IntroScreen: {
    title: 'La journée internationale des femmes ',
    tagline:
      'aura lieu le vendredi 8 mars 2019, et c’est une journée où l’on célèbre à l’échelle mondiale les réalisations des femmes sur les plans social, économique, culturel et politique. ',
    additionalP1:
      'Cette année, le thème de la Journée internationale des femmes, « L’équité pour le mieux (Balance for Better) », est un appel à l’action afin d’accélérer la parité entre les genres partout dans le monde (pour une salle du conseil, un gouvernement, une couverture médiatique, des employés et un patrimoine équilibrés entre les genres) tout en comprenant que cet équilibre est essentiel à l’épanouissement des économies et des communautés. ',
    additionalP2:
      'BMO désire souligner les gains que les femmes ont réalisés en devenant plus autonomes d’un point de vue économique. Nous avons conçu ce jeu-questionnaire afin de montrer combien l’émancipation économique est importante, pas seulement pour les femmes, mais pour tous ceux qui croient en un meilleur avenir économique pour tous.',
    startButton: 'COMMENCER LE QUIZ',
    learnMore: 'De meilleurs services bancaires',
    back: 'Précédent',
  },
  QuestionScreen: {
    SubmitAnswer: 'Soumettre',
  },
  AnswerScreen: {
    CorrectAnswerMessage: 'Réponse : ',
    Next: 'Suivant',
    PlayerAnswered: 'Vous avez soumis ',
    AnsweredCorrect: 'Excellent résultat',
    AnsweredIncorrect: 'Bel effort',
  },
  FollowUpScreen: {
    Next: 'Suivant ',
  },
  StatsScreen: {
    ReturnButton: 'Terminé',
    ThankYouMessage: 'Thanks for playing',
    Score: 'Votre résultat : ',
    additionalP1:
      'Avez-vous été surpris par ce que vous avez appris? Partagez ce jeu-questionnaire avec un ami afin de comparer vos résultats. ',
    additionalP2:
      'Vous désirez en savoir plus sur ce que fait BMO pour soutenir l’autonomie des femmes d’un point de vue économique? Veuillez consulter le site ',
    siteLinkLabel: 'bmopourelles.com',
    siteLink:
      'https://bmopourelles.bmo.com/hub/posts/bmo-pour-elles-celebre-la-journee-internationale-des-femmes-25254309',
    contactP1:
      'Veuillez communiquer avec nous dès aujourd’hui si vous avez des questions, ou pour prendre rendez-vous avec un conseiller local qui vous aidera à réaliser vos objectifs personnels ou d’affaires',
    connectButton: 'Établir une connexion maintenant',
    betterBankingLabel: 'De meilleurs services bancaires',
    back: 'Précédent',
  },
  Social: {
    FB: 'https://www.facebook.com/BMOcommunaute/',
    Twitter: 'https://twitter.com/bmofr?lang=en',
    Linkedin:
      'https://www.linkedin.com/company/bmo-groupe-financier/?originalSubdomain=ca',
    Contact: 'https://www.bmo.com/principal/contactez-nous',
    Vanity: 'https://bmo.com/bmopourelles',
  },
  NavBar: {
    EnglishButton: 'EN',
    FrenchButton: 'FR',
  },
};
