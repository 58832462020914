// Theme Settings
// https://rebassjs.org/theming
const BMOBlueDark = '#004a7c';
const BMOBlue = '#0079c1';
const BMOBlue80 = '#3394cd';
const BMOBlue60 = '66afda';
const BMOBlue40 = '#99c9e6';
const BMOBlue20 = '#cce4f3';
const BMORed = '#ed1c24';
const BMOGrey100 = '#4e4e4e';
const BMOGrey80 = '#6e6e6e';
const BMOGrey60 = '#8c8c8c';
const BMOGrey40 = '#aaaaaa';
const BMOGrey20 = '#c8c8c8';
const BMOBrightGreen = '#8dc63f';
const BMOBrightOrange = '#f15a22';
// helpers
export const tint = color => ({ theme }) => theme.colors[color];

export const colorSwitch = (a, b) => ({ theme, checked }) =>
  checked ? a({ theme }) : b({ theme });

export default {
  breakpoints: ['48em', '64em', '65em', '72em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48, 64, 80, 96, 112],
  textStyles: {
    caps: {
      textTransform: 'capitalize',
      letterSpacing: '0.2em',
    },
  },
  colors: {
    BMOBlueDark: BMOBlueDark,
    BMOBlue: BMOBlue,
    BMOBlue80: BMOBlue80,
    BMOBlue60: BMOBlue60,
    BMOBlue40: BMOBlue40,
    BMOBlue20: BMOBlue20,
    BMORed: BMORed,
    BMOGrey100: BMOGrey100,
    BMOGrey80: BMOGrey80,
    BMOGrey60: BMOGrey60,
    BMOGrey40: BMOGrey40,
    BMOGrey20: BMOGrey20,
    BMOBrightGreen: BMOBrightGreen,
    BMOBrightOrange: BMOBrightOrange,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: 'Dax,Roboto, Open Sans, system-ui, sans-serif',
    mono: 'Menlo, monospace',
    secondary: 'emonaregular',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  buttons: {
    primary: {
      color: 'white',
      backgroundColor: BMOBlue,
      textTransform: 'uppercase',
    },
    outline: {
      color: BMOBlue,
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
      textTransform: 'uppercase',
    },
    submit: {
      color: BMOBlue,
      borderRadius: '2rem/50%',
      padding: '1rem',
      backgroundColor: 'white',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: BMOBlueDark,
        color: 'white',
      },
      '&:disabled,&[disabled]': {
        backgroundColor: BMOGrey20,
      },
    },
    submit2: {
      color: BMOBlue,
      borderRadius: '2rem/50%',
      padding: '1rem',
      backgroundColor: 'white',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: BMOBlue,
        color: 'white',
      },
      '&:disabled,&[disabled]': {
        backgroundColor: BMOGrey20,
      },
    },
    submitQuestion: {
      color: 'white',
      borderRadius: '1.5rem',
      padding: '1rem',
      height: '3rem',
      backgroundColor: BMOBlue,
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: BMOBlueDark,
        color: 'white',
      },
      '&:disabled,&[disabled]': {
        backgroundColor: BMOGrey20,
        color: BMOBlue,
      },
      maxWidth: '24rem',
    },
    submitFollowUp: {
      color: BMOBlue,
      borderRadius: '1.5rem',
      padding: '1rem',
      height: '3rem',
      maxWidth: '24rem',
      backgroundColor: 'white',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: BMOBlueDark,
        color: 'white',
      },
      '&:disabled,&[disabled]': {
        backgroundColor: BMOGrey20,
        color: BMOBlue,
      },
    },
    contact: {
      color: BMOBlue,
      borderRadius: '1.5rem',
      padding: '1rem',
      height: '3rem',
      maxWidth: '24rem',
      backgroundColor: 'white',
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: BMOBlue,
        color: 'white',
      },
      '&:disabled,&[disabled]': {
        backgroundColor: BMOGrey20,
        color: BMOBlue,
      },
    },
    round: {
      borderRadius: '50%',
      marginRight: '4px',
      width: '2.5rem',
      height: '2.5rem',
      displayInline: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
    },
    roundNav: {
      borderRadius: '50%',
      color: BMOBlue,
      backgroundColor: 'white',
      width: '2.5rem',
      height: '2.5rem',
      fontSize: '1.25rem',
      margin: '0.25rem',
      textAlign: 'center',
      padding: 0,
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: BMOBlueDark,
        color: 'white',
      },
      '&:active': {
        backgroundColor: BMOGrey100,
        color: 'white',
      },
      '&:disabled,&[disabled]': {
        backgroundColor: BMOGrey20,
        color: BMOBlue,
      },
    },
    share: {
      borderRadius: '50%',
      width: '4rem',
      height: '4rem',
      fontSize: '1.5rem',
      backgroundColor: 'white',
      margin: '0.25rem',
      color: BMOBlue,
      textTransform: 'uppercase',
      '&:hover': {
        backgroundColor: BMOBlueDark,
        color: 'white',
      },
    },
  },
};
