import {
  Questions as enQuestions,
  ScreenText as enScreenText,
} from './english';
import { Questions as frQuestions, ScreenText as frScreenText } from './french';

export const gameText = {
  english: {
    Questions: enQuestions,
    ScreenText: enScreenText,
  },
  french: {
    Questions: frQuestions,
    ScreenText: frScreenText,
  },
};
