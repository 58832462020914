import React, { Component } from 'react';
import { Button, Flex, Text } from 'rebass';
import Translate from '../Components/Language/TranslateComponent';
import { HFlex } from '../Components/NavBar';
import { ImageFlexBox } from '../Components/ImageFlexBox';
import background from '../imgs/question-bg.jpg';
import styled from 'styled-components';
import { style } from 'styled-system';

const textTransform = style({
  // React prop name and CSS property
  prop: 'textTransform',
  // CSS property (if different from prop argument)
  cssProperty: 'textTransform',
  // key for theme values
  key: 'textTransform',

  alias: 'tt',
});

const TextTransformer = styled(HFlex)`
  ${textTransform}
`;

class AnswerFollowUp extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.selectedAnswer) return true;
    return false;
  }
  render() {
    const { question, selectedAnswer: answer } = this.props;
    const display = question ? this.renderAnswer(question, answer) : null;
    return display;
  }
  renderAnswer(question, answer) {
    const selID = answer ? answer.id : 0;
    const isCorrect = answer ? answer.correct : false;

    const fullAnswer = question.CorrectAnswer;
    const display = fullAnswer.indexOf(' ');
    const unit =
      display === -1 ? null : fullAnswer.substr(fullAnswer.indexOf(' ') + 1);
    const answerShort = fullAnswer.substr(0, fullAnswer.indexOf(' '));
    const appendUnit = question.AnswerUnit.length > 1;

    const CorrectAnswer =
      answerShort !== ''
        ? `${answerShort}`
        : appendUnit
        ? `${question.CorrectAnswer}`
        : `${question.CorrectAnswer}${question.AnswerUnit}`;

    const UnitDisplay =
      display !== -1 || appendUnit ? (
        <TextTransformer
          fontSize={[4, 5]}
          color="BMOBlue"
          textTransform="capitalize"
        >
          {unit || question.AnswerUnit}
        </TextTransformer>
      ) : null;

    const playerAnswer = `${question.PossibleAnswers[selID || 0]} ${
      question.AnswerUnit
    }`;

    const sourceObject = this.GetSource(question.FollowSource);

    const sourceDisplay = sourceObject ? (
      <a
        href={sourceObject.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'white' }}
      >
        {sourceObject.label}
      </a>
    ) : null;

    return (
      <ImageFlexBox
        backgroundSize="cover"
        backgroundPosition="center"
        flex="1"
        flexWrap="wrap"
        flex-direction={['row', 'row', 'row', 'column']}
        backgroundImage={`url(${background})`}
      >
        <HFlex
          bg="BMOBlue"
          px={3}
          pt={'3rem'}
          pb={1}
          flex="1"
          flexDirection="column"
          justifyContent="center"
          width={[1, 1, 1, 2 / 3]}
        >
          <Text
            color="white"
            textAlign={['center']}
            fontSize={[2, 2, 4, 5, 6]}
            fontWeight="400"
          >
            {question.QuestionText}
          </Text>
        </HFlex>
        <Flex
          width={[1, 1, 1, 2 / 3]}
          flexDirection="column"
          alignItems="center"
        >
          <HFlex
            bg={isCorrect ? 'BMOBrightGreen' : 'BMOBrightOrange'}
            p={3}
            width={[1]}
            justifyContent="center"
            flexDirection="column"
            alignSelf="flex-end"
          >
            <Text
              color="white"
              textAlign={['center']}
              fontSize={[5, 6]}
              fontWeight="bolder"
            >
              <Translate
                textId={
                  isCorrect
                    ? 'ScreenText.AnswerScreen.AnsweredCorrect'
                    : 'ScreenText.AnswerScreen.AnsweredIncorrect'
                }
                fallback="Nice Try!"
              />
            </Text>
            <Text color="white" textAlign={['center']} fontSize={[4, 5]}>
              <Translate
                textId="ScreenText.AnswerScreen.PlayerAnswered"
                fallback="Next"
              />{' '}
              {playerAnswer}
            </Text>
          </HFlex>
          <Flex
            width={1}
            flex="1 1 auto"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Text fontSize={[4, 5]} color="BMOBlue">
              <Translate
                textId="ScreenText.AnswerScreen.CorrectAnswerMessage"
                fallback="The answer is"
              />
            </Text>
            <Text fontSize={[9]} color="BMOBlue" fontWeight="bolder">
              {CorrectAnswer}
            </Text>
            {UnitDisplay}
            <Button
              tabIndex="0"
              variant="submitQuestion"
              my="2rem"
              width={['90%', 1 / 2]}
              onClick={this.onSubmit}
              aria-label={'Next Question'}
              autoFocus
            >
              <Translate
                textId="ScreenText.AnswerScreen.Next"
                fallback="Next"
              />
            </Button>
          </Flex>
          <HFlex
            bg="BMOBlueDark"
            p={[2, 2, 2, 3]}
            width={[1]}
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Flex flexDirection="column" px={0} py={0} width={[1, 1, 1, 4 / 5]}>
              <Text color="white" textAlign={['left']} fontSize={[1, 2]} my="2">
                {question.FollowText}
              </Text>

              <Text
                color="white"
                textAlign={['left']}
                fontSize={[0]}
                fontWeight="100"
                py={1}
              >
                {sourceDisplay || question.FollowSource}
              </Text>
            </Flex>
          </HFlex>
        </Flex>
      </ImageFlexBox>
    );
  }
  onSubmit = () => {
    this.props.OnConfirm();
  };

  GetSource = Source => {
    let followSource = Source;
    const i = followSource.indexOf('http');
    if (i >= 0) {
      const url = followSource.slice(i);
      let hostname = new URL(url).hostname;
      hostname = hostname.slice(4);
      hostname = hostname.charAt(0).toUpperCase() + hostname.slice(1);
      hostname = 'Source: ' + hostname;

      followSource = hostname;

      return {
        label: followSource,
        url: url,
      };
    }
    return null;
  };
}

export default AnswerFollowUp;
