/*eslint no-template-curly-in-string: "off"*/

export const Questions = [
  {
    QuestionText:
      'By 2020, how much of the total of private wealth are women expected to hold (in Trillions)?',
    PossibleAnswers: ['$10', '$24', '$72', '$150'],
    AnswerUnit: 'Trillion',
    CorrectAnswer: '$72',
    Image: '',
    FollowText:
      '$72 Trillion (32% of the total). According to the Boston Consulting Group, between 2010 and 2015 private wealth held by women grew from $34 Trillion to $51 Trillion. By 2020 they are expected to hold $72 Trillion, 32% of the total. And most of the private wealth that changes hands in the coming decades is likely to go to women. ',
    FollowSource:
      'Source: https://www.economist.com/graphic-detail/2018/03/08/womens-wealth-is-rising',
  },
  {
    QuestionText:
      'How much better is the financial performance of companies with more gender diversity on leadership teams (measured by return on equity)? ',
    PossibleAnswers: ['10.5%', '22.6%', '36.4%', '42.4%'],
    AnswerUnit: '',
    CorrectAnswer: '36.4%',
    Image: '',
    FollowText:
      '36.4%. Companies with gender diverse teams enjoy a 36.4% (1) improvement in financial performance compared to those that have less gender diverse teams—and that’s not all...Companies with a higher representation of women in executive leadership also have been seen to experience a 20% (2) higher share price performance.',
    FollowSource:
      'Sources: 1. MSCI, Women on Boards: Global Trends in Gender Diversity on Corporate Boards, November 2015 and 2. Credit Suisse, The CS Gender 3000: Women in Senior Management, September 2014.',
  },
  {
    QuestionText:
      'If women and men were granted equal opportunity in the global workforce, it would add $___ to the global economy.',
    PossibleAnswers: [
      '$10 Billion',
      '$120 Billion',
      '$3 Trillion',
      '$12 Trillion',
    ],
    AnswerUnit: '',
    CorrectAnswer: '$12 Trillion',
    Image: '',
    FollowText:
      '$12 Trillion. Equal opportunity for men and women in the workforce globally would add $12 Trillion to the economy, which is equivalent in size to the current GDP of Germany, Japan, and the United Kingdom combined.',
    FollowSource:
      'Source:  https://www.mckinsey.com/featured-insights/employment-and-growth/how-advancing-womens-equality-can-add-12-trillion-to-global-growth',
  },
  {
    QuestionText:
      'During the timeframe of 2016 to 2017, across 74 economies worldwide, how many women started businesses globally?',
    PossibleAnswers: ['5', '83', '115', '163'],
    CorrectAnswer: '163',
    AnswerUnit: 'Million',
    Image: '',
    FollowText:
      '163 Million. This number has increased by 57% since 2010 where 104 Million women started businesses across 59 economies. Despite the bias and hurdles that women face, this is an outstanding feat!',
    FollowSource:
      'Source: Global Entrepreneurship Monitor, Women’s Entrepreneurship 2016/2017 Report',
  },
];

export const ScreenText = {
  IntroScreen: {
    title: 'International Women’s Day',
    tagline:
      'is on Friday March 8, 2019 and is a global day of celebrating the social, economic, cultural and political achievements of women.',
    additionalP1:
      'This year’s IWD theme is “Balance For Better” and is a call-to-action for accelerating gender parity across the world: the gender-balanced boardroom, government, media coverage, employees, and wealth – understanding that gender balance is essential for economies and communities to thrive. ',
    additionalP2:
      'BMO wants to highlight the gains women have made in becoming more economically empowered. We’ve created this quiz to demonstrate how economic empowerment matters—not just to women, but to all who believe in a better economic future for everyone.',
    startButton: 'START THE QUIZ',
    learnMore: 'Better Banking',
    aboutProjectTitle: 'About',
    back: 'Back',
  },
  QuestionScreen: {
    SubmitAnswer: 'Submit',
  },
  AnswerScreen: {
    CorrectAnswerMessage: 'The answer is:',
    Next: 'Next',
    PlayerAnswered: 'You submitted ',
    AnsweredCorrect: 'Excellent score',
    AnsweredIncorrect: 'Good effort',
  },
  FollowUpScreen: {
    Next: 'Next',
  },
  StatsScreen: {
    ReturnButton: 'Completed',
    ThankYouMessage: 'Thanks for playing',
    Score: 'Your score: ',
    additionalP1:
      'Were you surprised by what you learned? Share this quiz with a friend to see how their score compares.',
    additionalP2:
      'Want to know more about what BMO is doing to support women’s economic empowerment? Visit ',
    siteLinkLabel: 'bmoforwomen.com',
    siteLink:
      'https://bmoforwomen.bmo.com/hub/posts/bmo-for-women-celebrates-international-womens-day-25251501',
    contactP1:
      'Contact us today with any questions, or to arrange a meeting with a local banker who can help you achieve your personal or business goals',
    connectButton: 'Connect Now',
    betterBankingLabel: 'Better Banking',
    back: 'Back',
  },
  NavBar: {
    EnglishButton: 'EN',
    FrenchButton: 'FR',
  },
  Social: {
    FB: 'https://www.facebook.com/BMOcommunity/',
    Twitter:
      'https://twitter.com/BMO?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    Linkedin:
      'https://www.linkedin.com/company/bank-of-montreal/?originalSubdomain=ca',
    Contact: 'https://www.bmo.com/main/contact-us',
    Vanity: 'https://bmo.com/bmoforwomen',
  },
};
